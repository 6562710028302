import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutMain } from '../../layout/main/Main';
import { RootReducerState } from '../../reducers';
import { getEquipmentsRequest } from '../../reducers/equipment/actions';
import { EquipmentList } from '../../component/equipment/EquipmentList';
import { EquipmentTabs } from './EquipmentTabs';
import { EquipmentMeta, EquipmentModel } from '../../reducers/equipment/model';
import { ProductStatusListType } from '../../reducers/forklift/model';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { ProductType } from '../../reducers/orders/model';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { addOrderRequest } from '../../reducers/orders/actions';
import { EquipmentHeader } from './EquipmentHeader';
import { useViewport } from '../../utils/customHooks/useViewport';
import { EquipmentMobileList } from '../../component/equipment/mobile/EquipmentMobileList';
import { useStateAntTable } from '../../utils/customHooks/useStateAntTable';
import { FilterQueryStringEquipmmentStrategy } from '../../services/filterQueryString/equipment.strategy';

export const EquipmentPage = () => {
  const equipmentList = useSelector<RootReducerState, EquipmentModel[]>(state => state.equipment.equipmentList);
  const equipmentMeta = useSelector<RootReducerState, EquipmentMeta | null>(state => state.equipment.equipmentMeta);
  const isFetchingEquipments = useSelector<RootReducerState, boolean>(state => state.equipment.isFetching);
  const [openEquipmentModal, setOpenEquipmentModal] = useState(false);
  const [equipmentStatusTab, setEquipmentStatusTab] = useState<ProductStatusListType>(ProductStatusListType.AVAILABLE);
  const [groupEditEquipments, setGroupEditEquipments] = useState<string[]>([]);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const { tableParams, onTableChange, clearTableParams } = useStateAntTable();

  const selectedEquipments = useMemo(() => {
    return equipmentList.filter(equipment => groupEditEquipments.includes(equipment._id));
  }, [equipmentList, groupEditEquipments]);

  useEffect(() => {
    dispatch(
      getEquipmentsRequest(
        new FilterQueryStringEquipmmentStrategy({
          filters: tableParams.filters,
          pagination: tableParams.pagination,
          sorter: tableParams.sorter,
          entityStatus: equipmentStatusTab,
        }).create(),
      ),
    );
  }, [dispatch, equipmentStatusTab, tableParams]);

  const handleSubmit = (values: OrderFormValues) => {
    dispatch(
      addOrderRequest(
        {
          ...values,
        },
        () => {
          setOpenOrderModal(false);
        },
      ),
    );
  };

  return (
    <LayoutMain>
      <EquipmentHeader
        onGroupEditEquipments={groupEditEquipments}
        onSetOpenEquipmentModal={setOpenEquipmentModal}
        onSelectedEquipments={selectedEquipments}
        onSetOpenOrderModal={setOpenOrderModal}
        onStatusChanged={() => {
          dispatch(
            getEquipmentsRequest(
              new FilterQueryStringEquipmmentStrategy({ filters: {}, entityStatus: equipmentStatusTab }).create(),
            ),
          );
        }}
      />
      <EquipmentTabs
        equipmentsAmount={equipmentMeta?.all ?? 0}
        equipmentsSoldAmount={equipmentMeta?.sold ?? 0}
        equipmentsAvailableAmount={equipmentMeta?.available ?? 0}
        equipmentsReservedAmount={equipmentMeta?.reserved ?? 0}
        onChange={status => {
          clearTableParams();
          setEquipmentStatusTab(status);
          setGroupEditEquipments([]);
        }}
      />
      {isMobile && (
        <EquipmentMobileList
          isFetchingEquipmentList={isFetchingEquipments}
          equipmentList={equipmentList}
          openEquipmentModal={openEquipmentModal}
          clickOpenEquipmentModal={openEquipmentModal => setOpenEquipmentModal(openEquipmentModal)}
          equipmentMeta={equipmentMeta}
          onTableChange={onTableChange}
          tableParams={tableParams}
        />
      )}
      {!isMobile && (
        <EquipmentList
          openEquipmentModal={openEquipmentModal}
          equipmentList={equipmentList}
          equipmentMeta={equipmentMeta}
          clickOpenEquipmentModal={openEquipmentModal => setOpenEquipmentModal(openEquipmentModal)}
          groupEditStatus={groupEditEquipments}
          setGroupEditStatus={selectedKeys => setGroupEditEquipments(selectedKeys)}
          selectedEquipmentTab={equipmentStatusTab}
          onTableChange={onTableChange}
          tableParams={tableParams}
        />
      )}
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        width={'80%'}
        openModal={openOrderModal}
        handleCloseModal={() => setOpenOrderModal(false)}
      >
        <OrderForm
          handleSubmit={handleSubmit}
          order={{
            productsInOrder: selectedEquipments.map(equipment => ({
              productId: equipment._id,
              price: equipment.price,
              notes: '',
              type: ProductType.EQUIPMENT,
              technicalReview: false,
              guarantee: false,
              udt: false,
              rectifier: false,
            })),
          }}
          handleCancelForm={() => setOpenOrderModal(false)}
        />
      </DrawerAddEdit>
    </LayoutMain>
  );
};
