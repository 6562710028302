import { FilterQueryStringBaseProps, FilterQueryStringBaseStrategy } from './base.strategy';
import { FilterValue } from 'antd/es/table/interface';
import { OrderStatus, OrderStatusListType } from '../../reducers/orders/model';

type ProductsStatusFilters = {
  status: OrderStatus[];
};

type FilterQueryStringOrderStrategyProps = FilterQueryStringBaseProps & {
  entityStatus: OrderStatusListType;
};

export class FilterQueryStringOrderStrategy extends FilterQueryStringBaseStrategy {
  entityStatus: OrderStatusListType;

  constructor(props: FilterQueryStringOrderStrategyProps) {
    const { entityStatus, ...rest } = props;
    super(rest);

    this.entityStatus = entityStatus;
  }

  protected mutateFiltersWithEntityStatus(queryMap: { filters: Record<string, FilterValue | undefined> }) {
    const productStatusFilters: ProductsStatusFilters = this.getProductStatusTypeFilters();

    if (queryMap.filters.status?.length) {
      queryMap.filters.status = queryMap.filters.status.filter(status =>
        productStatusFilters.status.length ? productStatusFilters.status.includes(status as OrderStatus) : true,
      );
      if (productStatusFilters.status.length && !queryMap.filters.status.length) {
        queryMap.filters.status = ['unknown'];
      }
    } else {
      queryMap.filters.status = productStatusFilters.status;
    }
  }

  private getProductStatusTypeFilters = () => {
    const productStatusFilters: ProductsStatusFilters = {
      status: [],
    };

    switch (this.entityStatus) {
      case OrderStatusListType.NOT_PAID:
        productStatusFilters.status = [
          OrderStatus.CANCEL,
          OrderStatus.LEASING,
          OrderStatus.NOT_PAID,
          OrderStatus.PAID_IN_ADVANCE,
        ];
        break;
      case OrderStatusListType.PAID:
        productStatusFilters.status = [OrderStatus.PAID];
        break;
      case OrderStatusListType.DONE:
        productStatusFilters.status = [OrderStatus.DONE];
        break;
    }

    return productStatusFilters;
  };
}
