import { FilterQueryStringBaseProps, FilterQueryStringBaseStrategy } from './base.strategy';
import { ProductStatus, ProductStatusListType } from '../../reducers/forklift/model';
import { FilterValue } from 'antd/es/table/interface';

type ProductsStatusFilters = {
  order: number | undefined;
  status: ProductStatus[];
};

type FilterQueryStringEquipmentStrategyProps = FilterQueryStringBaseProps & {
  entityStatus: ProductStatusListType;
};

export class FilterQueryStringEquipmmentStrategy extends FilterQueryStringBaseStrategy {
  entityStatus: ProductStatusListType;

  constructor(props: FilterQueryStringEquipmentStrategyProps) {
    const { entityStatus, ...rest } = props;
    super(rest);

    this.entityStatus = entityStatus;
  }

  protected mutateFiltersWithEntityStatus(queryMap: { filters: Record<string, FilterValue | undefined> }) {
    const productStatusFilters: ProductsStatusFilters = this.getProductStatusTypeFilters();

    if (productStatusFilters.order !== undefined) {
      queryMap.filters.order = [productStatusFilters.order];
    }
    if (queryMap.filters.status?.length) {
      queryMap.filters.status = queryMap.filters.status.filter(status =>
        productStatusFilters.status.length ? productStatusFilters.status.includes(status as ProductStatus) : true,
      );
      if (productStatusFilters.status.length && !queryMap.filters.status.length) {
        queryMap.filters.status = ['unknown'];
      }
    } else {
      queryMap.filters.status = productStatusFilters.status;
    }
  }

  private getProductStatusTypeFilters = () => {
    const productStatusFilters: ProductsStatusFilters = {
      order: undefined,
      status: [],
    };

    switch (this.entityStatus) {
      case ProductStatusListType.AVAILABLE:
        productStatusFilters.order = 0;
        productStatusFilters.status = [
          ProductStatus.WORKSHOP,
          ProductStatus.PAINTSHOP,
          ProductStatus.TRANSPORT_TO_ME,
          ProductStatus.MAGAZINE,
          ProductStatus.PHOTO,
        ];
        break;
      case ProductStatusListType.SOLD:
        productStatusFilters.status = [
          ProductStatus.TO_TRANSPORT_TO_CLIENT,
          ProductStatus.TRANSPORT_TO_CLIENT,
          ProductStatus.GOT_BY_CLIENT,
        ];
        break;
      case ProductStatusListType.IN_ORDER_RESERVED:
        productStatusFilters.order = 1;
        productStatusFilters.status = [
          ProductStatus.TRANSPORT_TO_ME,
          ProductStatus.MAGAZINE,
          ProductStatus.WORKSHOP,
          ProductStatus.PAINTSHOP,
          ProductStatus.PHOTO,
        ];
        break;
    }

    return productStatusFilters;
  };
}
