import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { ForkliftList } from '../../component/forklift/ForkliftList';
import { LayoutMain } from '../../layout/main/Main';
import { RootReducerState } from '../../reducers';
import { ForkliftModel, ProductStatusListType, LocalStorageKeys, ForkliftMeta } from '../../reducers/forklift/model';
import { getForkliftsRequest } from '../../reducers/forklift/actions';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { addOrderRequest } from '../../reducers/orders/actions';
import { ForkliftsTabs } from './ForkliftsTabs';
import { ProductType } from '../../reducers/orders/model';
import { ReservationForm, ReservationFormValues } from '../../forms/reservations';
import { addReservationRequest } from '../../reducers/reservations/actions';
import { useViewport } from '../../utils/customHooks/useViewport';
import { ForkliftMobileList } from '../../component/forklift/mobile/ForkliftMobileList';
import { OfferForm, OfferFormValues } from '../../forms/offers';
import { addOfferRequest, getOfferPDFRequest } from '../../reducers/offers/actions';
import { ForkliftsHeader } from './ForkliftsHeader';
import { useStateAntTable } from '../../utils/customHooks/useStateAntTable';
import { FilterQueryStringForkliftStrategy } from '../../services/filterQueryString/forklift.strategy';

import './style.scss';

export const ForkliftsPage = () => {
  const forkliftsList = useSelector<RootReducerState, ForkliftModel[]>(state => state.forklifts.forkliftsList);
  const forkliftsMeta = useSelector<RootReducerState, ForkliftMeta | null>(state => state.forklifts.forkliftsMeta);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.forklifts.isFetching);
  const [openForkliftModal, setOpenForkliftModal] = useState(false);
  const [productStatusTab, setProductStatusTab] = useState<ProductStatusListType>(ProductStatusListType.AVAILABLE);
  const [groupEditForklifts, setGroupEditForklifts] = useState<string[]>([]);
  const [tableSettings, setTableSettings] = useState<string[]>(
    JSON.parse(window.localStorage.getItem(LocalStorageKeys.FORKLIFT_TABLE_SETTINGS) ?? '[]') || [],
  );
  const dispatch = useDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openReservationModal, setOpenReservationModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const { isMobile } = useViewport();
  const { tableParams, onTableChange, clearTableParams } = useStateAntTable();

  const selectedForklifts = useMemo(
    () => forkliftsList.filter(forklift => groupEditForklifts.includes(forklift._id)),
    [forkliftsList, groupEditForklifts],
  );

  useEffect(() => {
    dispatch(
      getForkliftsRequest(
        new FilterQueryStringForkliftStrategy({
          filters: tableParams.filters,
          pagination: tableParams.pagination,
          sorter: tableParams.sorter,
          entityStatus: productStatusTab,
        }).create(),
      ),
    );
  }, [dispatch, productStatusTab, tableParams]);

  const handleSubmit = (values: OrderFormValues) => {
    dispatch(
      addOrderRequest({ ...values }, () => {
        setOpenOrderModal(false);
        dispatch(
          getForkliftsRequest(
            new FilterQueryStringForkliftStrategy({
              filters: {},
              entityStatus: productStatusTab,
            }).create(),
          ),
        );
      }),
    );
  };

  const handleSubmitReservation = (values: ReservationFormValues) => {
    dispatch(
      addReservationRequest(
        {
          ...values,
        },
        () => {
          setOpenReservationModal(false);
        },
      ),
    );
  };

  const handleSubmitOffer = (values: OfferFormValues) => {
    const offerValue = {
      clientId: values.clientId ? values.clientId : null,
      currency: values.currency,
      expiredAt: values.expiredAt,
      notes: values.notes,
      offerNr: values.offerNr,
      productsInOffer: values.productsInOffer,
    };
    dispatch(
      addOfferRequest({ ...offerValue }, offerId => {
        dispatch(getOfferPDFRequest(offerId));
        setOpenOfferModal(false);
      }),
    );
  };

  return (
    <LayoutMain>
      <ForkliftsHeader
        setOpenForkliftModal={setOpenForkliftModal}
        setOpenOrderModal={setOpenOrderModal}
        setOpenReservationModal={setOpenReservationModal}
        setOpenOfferModal={setOpenOfferModal}
        selectedForklifts={selectedForklifts}
        groupEditForklifts={groupEditForklifts}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        onStatusChanged={() => {
          dispatch(
            getForkliftsRequest(
              new FilterQueryStringForkliftStrategy({ filters: {}, entityStatus: productStatusTab }).create(),
            ),
          );
        }}
      />
      <ForkliftsTabs
        forkliftsAmount={forkliftsMeta?.all ?? 0}
        forkliftsSoldAmount={forkliftsMeta?.sold ?? 0}
        forkliftsAvailableAmount={forkliftsMeta?.available ?? 0}
        forkliftsReservedAmount={forkliftsMeta?.reserved ?? 0}
        onChange={status => {
          clearTableParams();
          setProductStatusTab(status);
          setGroupEditForklifts([]);
        }}
      />
      {isMobile && (
        <ForkliftMobileList
          isFetchingForkliftsList={isFetching}
          forkliftsList={forkliftsList}
          openForkliftModal={openForkliftModal}
          clickOpenForkliftModal={openForkliftModal => setOpenForkliftModal(openForkliftModal)}
          forkliftsMeta={forkliftsMeta}
          tableParams={tableParams}
          onTableChange={onTableChange}
        />
      )}
      {!isMobile && (
        <ForkliftList
          openForkliftModal={openForkliftModal}
          clickOpenForkliftModal={openForkliftModal => setOpenForkliftModal(openForkliftModal)}
          forkliftsList={forkliftsList}
          forkliftsMeta={forkliftsMeta}
          groupEditStatus={groupEditForklifts}
          setGroupEditStatus={selectedKeys => setGroupEditForklifts(selectedKeys)}
          tableSettings={tableSettings}
          selectedForkliftTab={productStatusTab}
          onTableChange={onTableChange}
          tableParams={tableParams}
        />
      )}
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        width={'80%'}
        openModal={openOrderModal}
        handleCloseModal={() => setOpenOrderModal(false)}
      >
        <OrderForm
          handleSubmit={handleSubmit}
          order={{
            productsInOrder: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              price: forklift.priceSuggested,
              notes: '',
              technicalReview: false,
              guarantee: false,
              udt: false,
              rectifier: false,
              type: ProductType.FORKLIFT,
            })),
          }}
          handleCancelForm={() => setOpenOrderModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Zarezerwuj wózki'
        titleAdd='Zarezerwuj wózki'
        width={'60%'}
        openModal={openReservationModal}
        handleCloseModal={() => setOpenReservationModal(false)}
      >
        <ReservationForm
          handleSubmit={handleSubmitReservation}
          handleCancelForm={() => setOpenReservationModal(false)}
          reservation={{
            productsInReservation: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              type: ProductType.FORKLIFT,
            })),
          }}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj ofertę'
        titleAdd='Utwórz ofertę'
        width={'60%'}
        openModal={openOfferModal}
        handleCloseModal={() => setOpenOfferModal(false)}
      >
        <OfferForm
          handleSubmit={handleSubmitOffer}
          handleCancelForm={() => setOpenOfferModal(false)}
          offer={{
            productsInOffer: selectedForklifts.map(forklift => ({
              productId: forklift._id,
              price: forklift.priceSuggested,
              notes: '',
              type: ProductType.FORKLIFT,
              assetId: forklift.assets[0]?._id,
            })),
          }}
        />
      </DrawerAddEdit>
    </LayoutMain>
  );
};
