import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { OrderMeta, OrderModel } from './model';

export type OrdersAction = ActionType<typeof actions>;
export type OrdersState = {
  readonly isFetching: boolean;
  readonly ordersList: OrderModel[];
  readonly ordersMeta: OrderMeta | null;
};

export const ordersReducer = combineReducers<OrdersState, OrdersAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_ORDERS_REQUEST:
      case CONST.ADD_ORDER_REQUEST:
      case CONST.UPDATE_ORDER_REQUEST:
      case CONST.DELETE_ORDER_REQUEST:
      case CONST.GET_ORDER_PDF_REQUEST:
        return true;

      case CONST.GET_ORDERS_SUCCESS:
      case CONST.GET_ORDERS_ERROR:
      case CONST.ADD_ORDER_SUCCESS:
      case CONST.ADD_ORDER_ERROR:
      case CONST.UPDATE_ORDER_SUCCESS:
      case CONST.UPDATE_ORDER_ERROR:
      case CONST.DELETE_ORDER_SUCCESS:
      case CONST.DELETE_ORDER_ERROR:
      case CONST.GET_ORDER_PDF_SUCCESS:
      case CONST.GET_ORDER_PDF_ERROR:
        return false;

      default:
        return state;
    }
  },
  ordersList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_ORDERS_SUCCESS:
        return action.payload.orders;

      case CONST.ADD_ORDER_SUCCESS:
        return [action.payload.order, ...state];

      case CONST.UPDATE_ORDER_SUCCESS: {
        const orderIdx = state.findIndex(item => item._id === action.payload.order._id);
        state.splice(orderIdx, 1, action.payload.order);

        return [...state];
      }

      case CONST.DELETE_ORDER_SUCCESS:
        return state.filter(order => order._id !== action.payload.orderId);

      default:
        return state;
    }
  },
  ordersMeta: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_ORDERS_SUCCESS:
        return action.payload.meta;

      default:
        return state;
    }
  },
});
