import React, { useEffect, useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { OrdersList } from '../../component/orders/OrdersList';
import { OrdersTabs } from './OrdersTabs';
import { OrderMeta, OrderModel, OrderStatusListType } from '../../reducers/orders/model';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { getOrdersRequest } from '../../reducers/orders/actions';
import { OrdersHeader } from './OrdersHeader';
import { useViewport } from '../../utils/customHooks/useViewport';
import { OrderMobileList } from '../../component/orders/mobile/OrderMobileList';
import { FilterQueryStringOrderStrategy } from '../../services/filterQueryString/order.strategy';
import { useStateAntTable } from '../../utils/customHooks/useStateAntTable';

export const OrdersPage = () => {
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const ordersList = useSelector<RootReducerState, OrderModel[]>(state => state.orders.ordersList);
  const ordersMeta = useSelector<RootReducerState, OrderMeta | null>(state => state.orders.ordersMeta);
  const isFetchingList = useSelector<RootReducerState, boolean>(state => state.orders.isFetching);
  const [ordersStatusTab, setOrdersStatusTab] = useState<OrderStatusListType>(OrderStatusListType.NOT_PAID);
  const { isMobile } = useViewport();
  const { tableParams, onTableChange, clearTableParams } = useStateAntTable();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrdersRequest(
        new FilterQueryStringOrderStrategy({
          filters: tableParams.filters,
          pagination: tableParams.pagination,
          sorter: tableParams.sorter,
          entityStatus: ordersStatusTab,
        }).create(),
      ),
    );
  }, [dispatch, ordersStatusTab, tableParams]);

  return (
    <LayoutMain>
      <OrdersHeader onSetOpenOrderModal={setOpenOrderModal} />
      <OrdersTabs
        ordersPaidAmount={ordersMeta?.paid ?? 0}
        ordersNotPaidAmount={ordersMeta?.notPaid ?? 0}
        ordersDoneAmount={ordersMeta?.done ?? 0}
        onChange={status => {
          clearTableParams();
          setOrdersStatusTab(status);
        }}
      />
      {isMobile && (
        <OrderMobileList
          ordersList={ordersList}
          isFetchingList={isFetchingList}
          openOrderModal={openOrderModal}
          clickOpenOrderModal={openOrderModal => setOpenOrderModal(openOrderModal)}
          ordersMeta={ordersMeta}
          tableParams={tableParams}
          onTableChange={onTableChange}
        />
      )}
      {!isMobile && (
        <OrdersList
          ordersList={ordersList}
          openOrderModal={openOrderModal}
          clickOpenOrderModal={openOrderModal => setOpenOrderModal(openOrderModal)}
          ordersMeta={ordersMeta}
          tableParams={tableParams}
          onTableChange={onTableChange}
        />
      )}
    </LayoutMain>
  );
};
