import React, { FC, useState } from 'react';
import { Badge, Button, type GetProps, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowUp, faXmark, faSearch } from '@fortawesome/free-solid-svg-icons';

import { FloatingActionButtonSC } from './styles';

const { Search } = Input;

type SearchProps = GetProps<typeof Input.Search>;
type Props = {
  children?: React.ReactNode;
  onSearch: (searchText: string) => void;
};

export const FloatingActionButton: FC<Props> = ({ children, onSearch }) => {
  const [defaultIcon, setDefaultIcon] = useState(faPlus);
  const [buttonClass, isButtonClass] = useState('hide');
  const [searchText, setSearchText] = useState('');
  const [showSearchField, setShowSearchField] = useState(false);

  const handleClickFloatingButton = () => {
    if (defaultIcon === faPlus) {
      setDefaultIcon(faXmark);
      isButtonClass('show');
      if (searchText) setShowSearchField(true);
    } else {
      setDefaultIcon(faPlus);
      isButtonClass('hide');
      setShowSearchField(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setDefaultIcon(faPlus);
    isButtonClass('hide');
  };

  const handleClickSearchButton = () => {
    setShowSearchField(true);
  };

  const handleSearch: SearchProps['onSearch'] = value => {
    onSearch(value);
  };

  const moreButtonsClass = `moreButtons${showSearchField ? ' rightSide' : ''}`;

  return (
    <FloatingActionButtonSC>
      {defaultIcon === faXmark && (
        <div className={moreButtonsClass}>
          <Button
            className='buttonArrowUp'
            type='primary'
            shape='circle'
            size='middle'
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            onClick={handleScrollToTop}
          />
          {!showSearchField && (
            <Button
              className='buttonSearch'
              type='primary'
              shape='circle'
              size='middle'
              icon={<FontAwesomeIcon icon={faSearch} />}
              onClick={handleClickSearchButton}
            />
          )}
          {showSearchField && (
            <Search
              className='fieldSearch'
              placeholder='Szukaj...'
              allowClear
              enterButton='Szukaj'
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onSearch={handleSearch}
            />
          )}
          {children}
        </div>
      )}
      <Badge dot={!!searchText}>
        <Button
          className={`mainFloatingButton ${buttonClass}`}
          type='primary'
          shape='circle'
          size='large'
          icon={<FontAwesomeIcon icon={defaultIcon} />}
          onClick={handleClickFloatingButton}
        />
      </Badge>
    </FloatingActionButtonSC>
  );
};
