import React, { FC } from 'react';
import { Button, Col, Dropdown, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { EquipmentModel } from '../../reducers/equipment/model';
import { itemsStatuses } from '../../utils/itemsStatus';
import { isProductStatus } from '../../utils/productsStatus';
import { ProductStatusListType } from '../../reducers/forklift/model';
import { useDispatch } from 'react-redux';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { EquipmentAdditionalMenu } from './EquipmentAdditionalMenu';

type EquipmentsHeaderProps = {
  onGroupEditEquipments: string[];
  onSetOpenEquipmentModal: (openEquipmentModal: boolean) => void;
  onSelectedEquipments: EquipmentModel[];
  onSetOpenOrderModal: (openOrderModal: boolean) => void;
  onStatusChanged?: () => void;
};

export const EquipmentHeader: FC<EquipmentsHeaderProps> = props => {
  const { onGroupEditEquipments, onSetOpenEquipmentModal, onSelectedEquipments, onSetOpenOrderModal, onStatusChanged } =
    props;
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const editItems = (selectedEquipments: EquipmentModel[]) => {
    const itemsStatus = itemsStatuses({ dispatch, equipmentIds: onGroupEditEquipments, onSuccess: onStatusChanged });
    itemsStatus.push({
      label: 'Zamówienie',
      key: 'order',
      onClick: () => {
        onSetOpenOrderModal(true);
      },
      disabled: !selectedEquipments.every(equipment =>
        isProductStatus(equipment, ProductStatusListType.READY_FOR_ORDER),
      ),
    });

    return itemsStatus;
  };

  return isMobile ? (
    <HeaderComponentMobile
      title='Osprzęt'
      onAddElementOpenModal={onSetOpenEquipmentModal}
    >
      <EquipmentAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Osprzęt'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenEquipmentModal(true)}
          >
            Dodaj osprzęt
          </Button>
        </Col>
        <Col>
          <Dropdown
            menu={{ items: editItems(onSelectedEquipments) }}
            arrow
            disabled={onGroupEditEquipments.length <= 0}
          >
            <Button disabled={onGroupEditEquipments.length <= 0}>Edytuj</Button>
          </Dropdown>
        </Col>
        <EquipmentAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
