import { useCallback, useState } from 'react';
import type { GetProp, TableProps } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

export type TableParams = {
  pagination: TablePaginationConfig;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
  sorter?: SorterResult<any> | SorterResult<any>[];
};

export const DEFAULT_PAGE_SIZE = 50;

export const useStateAntTable = () => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    },
  });

  const onTableChange = useCallback(
    (pagination, filters, sorter) => {
      setTableParams({
        pagination,
        filters,
        sorter,
      });
    },
    [setTableParams],
  );

  const clearTableParams = useCallback(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    });
  }, [tableParams]);

  return {
    tableParams,
    onTableChange,
    clearTableParams,
  };
};
