import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { EquipmentInternalId, EquipmentMeta, EquipmentModel } from './model';

export type EquipmentAction = ActionType<typeof actions>;
export type EquipmentState = {
  readonly isFetching: boolean;
  readonly isFetchingStockList: boolean;
  readonly equipmentList: EquipmentModel[];
  readonly equipmentMeta: EquipmentMeta | null;
  readonly equipmentInternalId: EquipmentInternalId | null;
};

export const equipmentReducer = combineReducers<EquipmentState, EquipmentAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENTS_REQUEST:
      case CONST.ADD_EQUIPMENT_REQUEST:
      case CONST.UPDATE_EQUIPMENT_REQUEST:
      case CONST.DELETE_EQUIPMENT_REQUEST:
      case CONST.UPDATE_EQUIPMENTS_STATUSES_REQUEST:
      case CONST.GET_EQUIPMENT_REQUEST:
      case CONST.GET_EQUIPMENT_INTERNAL_ID_REQUEST:
        return true;

      case CONST.GET_EQUIPMENTS_SUCCESS:
      case CONST.GET_EQUIPMENTS_ERROR:
      case CONST.ADD_EQUIPMENT_SUCCESS:
      case CONST.ADD_EQUIPMENT_ERROR:
      case CONST.UPDATE_EQUIPMENT_SUCCESS:
      case CONST.UPDATE_EQUIPMENT_ERROR:
      case CONST.DELETE_EQUIPMENT_SUCCESS:
      case CONST.DELETE_EQUIPMENT_ERROR:
      case CONST.UPDATE_EQUIPMENTS_STATUSES_SUCCESS:
      case CONST.UPDATE_EQUIPMENTS_STATUSES_ERROR:
      case CONST.GET_EQUIPMENT_SUCCESS:
      case CONST.GET_EQUIPMENT_ERROR:
      case CONST.GET_EQUIPMENT_INTERNAL_ID_SUCCESS:
      case CONST.GET_EQUIPMENT_INTERNAL_ID_ERROR:
        return false;

      default:
        return state;
    }
  },
  isFetchingStockList: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENT_STOCK_LIST_REQUEST:
        return true;
      case CONST.GET_EQUIPMENT_STOCK_LIST_SUCCESS:
      case CONST.GET_EQUIPMENT_STOCK_LIST_ERROR:
        return false;
      default:
        return state;
    }
  },
  equipmentList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENTS_SUCCESS:
        return action.payload.equipments;

      case CONST.ADD_EQUIPMENT_SUCCESS:
        return [action.payload.equipment, ...state];

      case CONST.GET_EQUIPMENT_SUCCESS:
      case CONST.UPDATE_EQUIPMENT_SUCCESS: {
        const equipmentIdx = state.findIndex(item => item._id === action.payload.equipment._id);
        state.splice(equipmentIdx, 1, action.payload.equipment);

        return [...state];
      }

      case CONST.UPDATE_EQUIPMENTS_STATUSES_SUCCESS: {
        action.payload.equipments.forEach(equipment => {
          const equipmentIdx = state.findIndex(item => item._id === equipment._id);
          state.splice(equipmentIdx, 1, equipment);
        });

        return [...state];
      }

      case CONST.DELETE_EQUIPMENT_SUCCESS:
        return state.filter(equipment => equipment._id !== action.payload.equipmentId);

      default:
        return state;
    }
  },
  equipmentMeta: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENTS_SUCCESS:
        return action.payload.meta;
      case CONST.ADD_EQUIPMENT_SUCCESS:
        if (!state) {
          return null;
        }
        return {
          ...state,
          available: state.available + 1,
          all: state.all + 1,
        };
      case CONST.DELETE_EQUIPMENT_SUCCESS:
        if (!state) {
          return null;
        }
        return {
          ...state,
          available: state.available - 1,
          all: state.all - 1,
        };
      default:
        return state;
    }
  },
  equipmentInternalId: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_EQUIPMENT_INTERNAL_ID_REQUEST:
        return null;

      case CONST.GET_EQUIPMENT_INTERNAL_ID_SUCCESS:
        return action.payload.equipmentInternalId;

      default:
        return state;
    }
  },
});
