import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { OrderMeta, OrderModel } from './model';

export const getOrdersRequest = (query?: string) => action(CONST.GET_ORDERS_REQUEST, { query });
export const getOrdersSuccess = (data: { orders: OrderModel[]; meta: OrderMeta }) =>
  action(CONST.GET_ORDERS_SUCCESS, { orders: data.orders, meta: data.meta });
export const getOrdersError = (error: unknown) => action(CONST.GET_ORDERS_ERROR, { error });

export const addOrderRequest = (
  order: Omit<OrderModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'ownerId' | 'price'>,
  onSuccess?: () => void,
) => action(CONST.ADD_ORDER_REQUEST, { order, onSuccess });
export const addOrderSuccess = (order: OrderModel) => action(CONST.ADD_ORDER_SUCCESS, { order });
export const addOrderError = (error: unknown) => action(CONST.ADD_ORDER_ERROR, { error });

export const updateOrderRequest = (
  order: Omit<OrderModel, 'client' | 'ownerId' | 'owner' | 'price'>,
  onSuccess?: () => void,
) => action(CONST.UPDATE_ORDER_REQUEST, { order, onSuccess });
export const updateOrderSuccess = (order: OrderModel) => action(CONST.UPDATE_ORDER_SUCCESS, { order });
export const updateOrderError = (error: unknown) => action(CONST.UPDATE_ORDER_ERROR, { error });

export const deleteOrderRequest = (orderId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_ORDER_REQUEST, { orderId, onSuccess });
export const deleteOrderSuccess = (orderId: string) => action(CONST.DELETE_ORDER_SUCCESS, { orderId });
export const deleteOrderError = (error: unknown) => action(CONST.DELETE_ORDER_ERROR, { error });

export const getOrderPDFRequest = (orderId: string, onSuccess?: () => void) =>
  action(CONST.GET_ORDER_PDF_REQUEST, { orderId, onSuccess });

export const getOrderPDFSuccess = () => action(CONST.GET_ORDER_PDF_SUCCESS);

export const getOrderPDFError = (error: unknown) => action(CONST.GET_ORDER_PDF_ERROR, { error });
