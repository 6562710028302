import { ClientModel, ClientType } from '../clients/model';
import { UserModel } from '../user/model';
import { AssetModel } from '../assets/model';
import { BrandModel } from '../brands/model';
import { ForkliftModel } from '../forklift/model';
import { EquipmentModel } from '../equipment/model';
import { PartModel } from '../parts/model';

export enum OrderStatus {
  NOT_PAID = 'not-paid',
  PAID = 'paid',
  PAID_IN_ADVANCE = 'paid-in-advance',
  LEASING = 'leasing',
  CANCEL = 'canceled',
  DONE = 'done',
}

export enum OrderStatusListType {
  NOT_PAID = 'not-paid',
  PAID = 'paid',
  DONE = 'done',
}

export const OrderStatusNames = {
  [OrderStatus.NOT_PAID]: 'Nie opłacone',
  [OrderStatus.PAID]: 'Opłacone',
  [OrderStatus.PAID_IN_ADVANCE]: 'Zaliczka',
  [OrderStatus.LEASING]: 'Leasing',
  [OrderStatus.CANCEL]: 'Anulowane',
  [OrderStatus.DONE]: 'Zrealizowane',
};

export enum ProductType {
  FORKLIFT = 'forklift',
  EQUIPMENT = 'equipment',
  PART = 'part',
}

export type AvailableProducts =
  | ForkliftModel
  | EquipmentModel
  | (PartModel & {
      serialNumber: undefined;
    });

export type ProductInOrder = {
  type: ProductType;
  productId: string;
  price: number;
  notes: string;
  technicalReview: boolean;
  guarantee: boolean;
  udt: boolean;
  rectifier: boolean;
};

export enum TransportType {
  SELLER = 'seller',
  CLIENT = 'client',
}

export const TransportTypeNames = {
  [TransportType.CLIENT]: 'Klient',
  [TransportType.SELLER]: 'Superlift',
};

export enum CurrencyType {
  PLN = 'pln',
  EUR = 'eur',
}

export const CurrencyTypeNames = {
  [CurrencyType.PLN]: 'PLN',
  [CurrencyType.EUR]: 'EUR',
};

export type OrderMeta = {
  count: number;
  total: number;
  notPaid: number;
  paid: number;
  done: number;
};

export type OrderModel = {
  _id: string;
  orderNr: string;
  clientId: string;
  productsInOrder: ProductInOrder[];
  price: number;
  advancePrice?: number;
  status: OrderStatus;
  paidAt?: string;
  ownerId: string;
  deleted: string;
  createdAt: string;
  updatedAt: string;
  notes?: string;
  clientType: ClientType;
  transport: TransportType;
  transportAddress?: string;
  currency: CurrencyType;
  invoice?: string;
  // virtuals fields
  owner?: UserModel;
  client?: ClientModel;
  products?: {
    _id: string;
    internalId: string;
    assets: AssetModel[];
    brand: BrandModel;
    serialNumber: string;
  }[];
};
