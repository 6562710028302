import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

export type FilterQueryStringBaseProps<T = unknown> = {
  pagination?: TablePaginationConfig;
  filters?: Record<string, FilterValue | null>;
  sorter?: SorterResult<T> | SorterResult<T>[];
};

export abstract class FilterQueryStringBaseStrategy {
  protected pagination?: TablePaginationConfig;
  protected filters?: Record<string, FilterValue | null>;
  protected sorter?: SorterResult<unknown> | SorterResult<unknown>[];

  protected constructor(props: FilterQueryStringBaseProps) {
    this.pagination = props.pagination;
    this.filters = props.filters;
    this.sorter = props.sorter;
  }

  create() {
    const sorterItem = Array.isArray(this.sorter) ? this.sorter[0] : this.sorter;

    const queryMap = {
      page: this.pagination?.current ?? 1,
      limit: this.pagination?.pageSize,
      sort: sorterItem?.field ?? 'createdAt',
      sortOrder: sorterItem?.order ?? 'descend',
      filters: Object.entries(this.filters ?? {}).reduce<Record<string, FilterValue>>(
        (filtersMap, [filterName, filterValue]) => {
          if (filterValue) {
            filtersMap[filterName] = filterValue;
          }
          return filtersMap;
        },
        {},
      ),
    };
    this.mutateFiltersWithEntityStatus(queryMap);

    return `?page=${queryMap.page}${
      queryMap.limit ? `&limit=${queryMap.limit}` : ''
    }&sort=${queryMap.sort.toString()}&sortOrder=${queryMap.sortOrder}&filters=${encodeURI(
      JSON.stringify(queryMap.filters),
    )}`;
  }

  protected mutateFiltersWithEntityStatus(queryMap?: Record<string, unknown>) {
    throw new Error(`Method not implemented for strategy: ${JSON.stringify(queryMap)}`);
  }
}
