import { BaseAPI } from '../base';
import {
  EquipmentInternalId,
  EquipmentMeta,
  EquipmentModel,
  EquipmentStatusesModel,
} from '../../../reducers/equipment/model';
import { ResponseModel, ResponseModelMeta } from '../../../reducers/model';
import { ProductStatusListType } from '../../../reducers/forklift/model';
import { prepareFilterQueryString } from '../../../utils/queryStringFiltering';

class APIClientEquipments extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/equipments`;
  }

  public getEquipments = async (query?: string) => {
    const queryString = query ?? '';
    return this.authFetch<ResponseModelMeta<EquipmentModel[], EquipmentMeta>>(`${this.apiURL}/${queryString}`, {
      method: 'GET',
    });
  };

  public getAvailableEquipments = async (): Promise<ResponseModel<EquipmentModel[]>> => {
    return this.authFetch<ResponseModel<EquipmentModel[]>>(
      `${this.apiURL}/${prepareFilterQueryString({ filters: {}, productStatusTab: ProductStatusListType.AVAILABLE })}`,
      { method: 'GET' },
    );
  };

  public addEquipment = async (
    data: Omit<EquipmentModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order'>,
  ): Promise<ResponseModel<EquipmentModel>> => {
    return this.authFetch<ResponseModel<EquipmentModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updateEquipment = async (data: Partial<EquipmentModel>): Promise<ResponseModel<EquipmentModel>> => {
    delete data.brand;
    delete data.category;
    delete data.order;
    return this.authFetch<ResponseModel<EquipmentModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deleteEquipment = async (equipmentId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${equipmentId}`, { method: 'DELETE' });
  };

  public updateEquipmentStatus = async (data: EquipmentStatusesModel): Promise<ResponseModel<EquipmentModel[]>> => {
    return this.authFetch<ResponseModel<EquipmentModel[]>>(`${this.apiURL}/statuses`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public getEquipment = async (equipmentId: string): Promise<ResponseModel<EquipmentModel>> => {
    return this.authFetch<ResponseModel<EquipmentModel>>(`${this.apiURL}/${equipmentId}`, { method: 'GET' });
  };

  public getEquipmentInternalId = async (): Promise<ResponseModel<EquipmentInternalId>> => {
    return this.authFetch<ResponseModel<EquipmentInternalId>>(`${this.apiURL}/internal-id`, { method: 'GET' });
  };

  public getStockList = async (): Promise<ArrayBuffer> => {
    return this.authFetchFileAsArrayBuffer(`${this.apiURL}/stock-list`, { method: 'GET' });
  };
}

export const apiClientEquipments = new APIClientEquipments();
