import React, { useEffect, useMemo, useState } from 'react';
import { Col, Flex } from 'antd';
import dayjs from 'dayjs';

import { Column } from '@ant-design/plots';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../reducers';
import { ReportSellModel } from '../../../reducers/reports/model';
import { getReportsRequest } from '../../../reducers/reports/actions';
import { ColumnConfig } from '@ant-design/plots/es/components/column';
import { RowSC, SelectSC, TitleSC } from './styles';

export const MonthlyReports: React.FC = () => {
  const reportsList = useSelector<RootReducerState, ReportSellModel[]>(state => state.reports.reportsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.reports.isFetching);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportsRequest());
  }, [dispatch]);

  const yearsInReports = [
    { value: 'all', label: 'Wszystkie' },
    ...Array.from(new Set(reportsList.map(report => dayjs(report.date).year())))
      .sort((a, b) => b - a)
      .map(year => {
        return {
          value: year.toString(),
          label: year,
        };
      }),
  ];

  const selectedReportsList = useMemo(() => {
    if (selectedYear === 'all') {
      return reportsList;
    } else {
      return reportsList.filter(report => dayjs(report.date).year().toString() === selectedYear);
    }
  }, [selectedYear, reportsList]);

  const totalSoldForklifts = selectedReportsList.reduce((acc, report) => acc + report.soldForklifts, 0);

  const data = useMemo(() => {
    return selectedReportsList.map(report => {
      return {
        ...report,
        date: dayjs(report.date).format('MM/YYYY'),
        soldForklifts: report.soldForklifts,
      };
    });
  }, [selectedReportsList]);

  const config: ColumnConfig = {
    data,
    xField: 'date',
    yField: 'soldForklifts',
    label: {
      text: 'soldForklifts',
      textBaseline: 'bottom',
    },
    tooltip: {
      items: [
        {
          name: 'Sprzedane',
          field: 'soldForklifts',
        },
      ],
    },
    offset: 10,
    slider: {
      x: {
        values: [0, 10],
      },
    },
  };
  return (
    <>
      <RowSC
        justify='space-between'
        align='middle'
      >
        <Col span={12}>
          <TitleSC level={5}>Ilość sprzedanych sztuk wózków: {totalSoldForklifts}</TitleSC>
        </Col>
        <Col span={12}>
          <Flex justify='flex-end'>
            <SelectSC
              value={selectedYear}
              onChange={(value: string) => setSelectedYear(value)}
              options={yearsInReports}
              disabled={!selectedYear}
            />
          </Flex>
        </Col>
      </RowSC>
      <Column
        {...config}
        loading={isFetching}
      />
    </>
  );
};
